import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Introduction from '../Introduction/index';
import Factsheet from '../Factsheet/index';
import Attribution from '../Attribution/index';
import News from '../News/index';
import { SwitchContainer } from './style';
import Switch from '../../components/Switch/index';
import { TApplicationState } from '../../redux/store';
import { switchValueType } from '../../redux/story/actions';
import { getValueTypeAsBoolean } from '../../redux/story/selectors';
import { IAction } from '../../ts/redux';
import Funds from '../Funds';

interface IStoryReduxState {
    switchValue: boolean,
    
};

interface IStoryReduxActions {
    switchValueType: Function,
}

type TStoryProps = IStoryReduxActions & IStoryReduxState;

function Story({switchValue, switchValueType}: TStoryProps): ReactElement {
    return (
        <>
            <Introduction />
            <Factsheet />
            <SwitchContainer>
                <Switch isOn={switchValue} onClick={() => switchValueType()} />
            </SwitchContainer>
            <Attribution />
            <News />
            <Funds />
        </>
    );
}

function mapStateToProps(state: TApplicationState): IStoryReduxState {
    return {
        switchValue: getValueTypeAsBoolean(state)
    };
}

function mapDispatchToProps(dispatch: Dispatch<IAction>): IStoryReduxActions {
    return {
        switchValueType: () => {
            dispatch(switchValueType());
        }
    }
}

const ConnectedStory = connect(mapStateToProps, mapDispatchToProps)(Story);

interface IStoryPageProps {}

export default function StoryPage(props: IStoryPageProps): ReactElement {
    return (
        <ConnectedStory />
    );  
}
