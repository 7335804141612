import axios, { AxiosResponse } from 'axios';

import { IFilePayload } from '../../redux/fileupload/actions';
import API_CONFIG from '../../config/api.config';
import { IAPIResponse, IAPIIntermediateResponse } from '../../ts/api';
// import { response } from './__mocks__/response';

export async function loadStoryData<T extends File & {path: string}>(payload: IFilePayload<T>): Promise<IAPIIntermediateResponse> {
    
    const [file] = payload.files;
    const formdata = new FormData();
    formdata.append('file', file);
    try {
        const response: AxiosResponse<IAPIIntermediateResponse> = await axios.post(API_CONFIG.ENDPOINT + API_CONFIG.VERSION, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (e) {
        return Promise.reject('Error while uploading');
    }
}

export async function loadDefaultStoryData(): Promise<IAPIIntermediateResponse> {
    try {
        const response: AxiosResponse<IAPIIntermediateResponse> = await axios.get(API_CONFIG.ENDPOINT + API_CONFIG.VERSION + '/demo');
        return response.data;
    } catch(e) {
        return Promise.reject('Error while loading the default story');
    }
}

export async function pingGeneratedStoryUrl(url: string): Promise<IAPIResponse> {
    try {
        const response: AxiosResponse<IAPIResponse> = await axios.get(url);
        return response.data;

        // return Promise.resolve(response);
    } catch(e) {
        return Promise.reject(e);
    }
}