import styled from 'styled-components';

export const SwitchComponent = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 25px;
    padding: 0 6px;
    
    background-color: ${({ theme }) => theme.colors.monochrome.eight };
    border-radius: 16px;
    
    cursor: pointer;
    user-select: none;

    img {
        display: block;
        width: 11px;
    }
`;

export const Thumb = styled.div<{isOn: boolean}>`
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 25px;
    height: 25px;

    background-color: ${({ theme }) => theme.colors.primary.two };
    border-radius: 100%;

    transition: all 200ms ease-in-out;
    transform: ${({isOn}) => isOn ? 'translate3d(100%, 0, 0)' : 'translate3d(0, 0, 0)'};
    cursor: pointer;

    img {
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        width: 11px;
        height: 11px;
        opacity: 0;

        transition: all 200ms ease-in-out;
        transform: translate(-50%, -50%);

        &.on {
            opacity: 1;
        }
    }
`;