import styled from 'styled-components';

import {H3} from '../Typography/headers';

export const QuoteComponent = styled.blockquote`
    position: relative;
    
    display: block; 
    max-width: 385px;
    margin: 30px 0 0 0;
    padding: 20px ${({ theme }) => theme.spacers.horizontal.desktop.xxs } 9px;

    border: 1px solid ${({ theme }) => theme.colors.monochrome.five };
`;

export const QuoteArrow = styled.img`
    display: block;
    width: 16px;
    height: 17px;

    position: absolute;
    bottom: -16px;
    right: 25%;
`;

export const QuoteText = styled(H3)`
    margin: 0;
    padding: 0;

    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;

    em {
        display: block;
        margin-top: 15px;

        font-style: normal;
        font-size: 11px;
    }
`;