import styled from 'styled-components';
import { H1 } from '../../components/Typography/headers';
import { ContentWrapper } from '../../components/Scaffolding/ContentWrapper';

export const BackgroundDecoration = styled.div`
    position: absolute;
    left: 25%;
    top: ${({theme}) => theme.spacers.vertical.desktop.m};
    
    display: block;
    height: calc(100% - 80px);
    width: 25%;
    
    background-color: ${({theme}) => theme.colors.monochrome.six};

    border-top: 4px solid ${({theme}) => theme.colors.primary.one};

    z-index: ${({theme}) => theme.layers.belowBaseLayer};
`;

export const ContentWrapperIntroduction = styled(ContentWrapper)`
    margin: ${({ theme }) => theme.spacers.vertical.desktop.s} ${({ theme }) => theme.spacers.horizontal.desktop.m};
`;

export const IntroductionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${({theme}) => theme.spacers.vertical.desktop.m};
`;

export const IntroductionTitle = styled(H1)`
    max-width: 500px;
`;

export const ImageWrapper = styled.div`
    position: relative;
    
    display: block;
    width: 100%;
    height: 360px;
    margin-top: -230px;

    img {
        position: absolute;
        top: 0;
        right: 0;

        display: block;
        max-width: 60%;
    }
`;

