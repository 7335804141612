import { IAPIResponse } from "../../ts/api";
import { INewsState, ITimeSeriesObject } from "../../ts/redux";

export function newsFormatter(payload: IAPIResponse): Promise<INewsState> {

    const newsData = payload.news;
    const timeseries: ITimeSeriesObject[] = Object.keys(newsData.timeseries).map((key:string) => {
        return newsData.timeseries[key];
    });
    const returnValue: INewsState = {
        title: newsData.title,
        timeseries: timeseries,
        heroTitle: {
            value: newsData.herotitle.value,
            percentageValue: newsData.herotitle.percentageValue,
            date: newsData.herotitle.date,
            source: newsData.herotitle.source,
            timeseriesIdentifier: newsData.herotitle.timeseriesidentifier,
            link: newsData.herotitle.link,
            icon: 'icon_news.svg'
        },
        villainTitle: {
            value: newsData.villaintitle.value,
            percentageValue: newsData.villaintitle.percentageValue,
            date: newsData.villaintitle.date,
            source: newsData.villaintitle.source,
            timeseriesIdentifier: newsData.villaintitle.timeseriesidentifier,
            link: newsData.villaintitle.link,
            icon: 'icon_news.svg'
        }
    };

    return Promise.resolve(returnValue)
}