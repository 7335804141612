import { IAPIResponse } from "../../ts/api";
import { IIntroductionState } from "../../ts/redux";

export function introductionFormatter(payload: IAPIResponse): Promise<IIntroductionState> {
    const introductionData = payload.intro;

    return Promise.resolve({
        period: introductionData.period,
        addressing: introductionData.addressing,
        title: introductionData.title,
        figure: introductionData.figure
    });
}