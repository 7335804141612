
import { TApplicationState } from "../store";
import { FACTSHEET_REDUCER_KEY } from "./reducer";
import { TReducerSelector, IToggleButtonValues } from "../../ts/general";
import { IFactsheetState } from "../../ts/redux";
import { IFactsheetCard } from "../../ts/components";

const reducerState: TReducerSelector<IFactsheetState> = (state: TApplicationState) => state[FACTSHEET_REDUCER_KEY];

export const getCurrentPeriod = (state: TApplicationState): IFactsheetCard => reducerState(state).current;

export const getPreviousPeriod = (state: TApplicationState): IFactsheetCard => reducerState(state).previous;

export const getQuote = (state: TApplicationState): string => reducerState(state).quote;

export const getExplanation = (state: TApplicationState): string => reducerState(state).explanation;

export const getToggleButtonValues = (state: TApplicationState): IToggleButtonValues => reducerState(state).comparison;