import styled, { css } from 'styled-components';

interface baseHeaderProps {
    bar?: boolean;
    barColor?: string;
}

const base = css<baseHeaderProps>`
    position: relative;
    display: block;

    font-family: escrow;
    font-weight: 700;
    color: ${({theme}) => theme.colors.system.text};

    &:after {
        content: ' ';
        position: absolute;
        bottom: -30px;
        left: 0;

        display: ${(props) => props.bar ? 'block' : 'none'};
        width: 24px;
        height: 4px;

        background-color: ${(props) => props.barColor ? props.barColor : props.theme.colors.primary.one};
    }
`;

export const H1 = styled.h1<baseHeaderProps>`
    ${base};

    font-size: 70px;
    line-height: 1.02;
`;

export const H2 = styled.h2`
    ${base};
    
    font-size: 40px;
    line-height: 1;

    &:after {
        bottom: -15px;
    }
`;

export const H3 = styled.h3`
    ${base};
    
    font-size: 24px;
    line-height: 1.3;
`;

interface h4Props {
    bold?: boolean;
}

export const H4 = styled.h4<h4Props>`
    font-family: Roboto;
    font-weight: ${(props) => props.bold ? 700 : 300};
    font-size: 11px;
    color: ${({theme}) => theme.colors.system.text}
`;