import {all, select, put} from 'redux-saga/effects';
import {fileUploadSagas} from './fileupload/sagas';
import { storySagas } from './story/sagas';
import { getHasLoadedStory } from './story/selectors';
import { toHome } from './location/actions';

export function* init() {
    const hasLoadStory = yield select(getHasLoadedStory);
    if (!hasLoadStory) {
        yield put(toHome());
    }
}


export function* rootSaga() {
    yield all([
        init(),
        fileUploadSagas(),
        storySagas()
    ]);
}