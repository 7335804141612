import React, {useCallback, ReactElement} from 'react'
import {useDropzone} from 'react-dropzone'
import { DropZoneComponent, IconWrapper } from './style';

import xlsxSvg from '../../assets/icons/xlsx.svg';
import xlsxGreySvg from '../../assets/icons/xlsx_grey.svg';

interface IDropZoneProps {
  onDrop<T extends File>(acceptedFiles: T[]): void;
}

export default function DropZone(props: IDropZoneProps): ReactElement {

  const onDrop = useCallback(props.onDrop, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <DropZoneComponent {...getRootProps()} active={isDragActive}>
        <input {...getInputProps()} />
        
        <IconWrapper>
            <img src={xlsxGreySvg} alt="XLSX Icon" className={!isDragActive ? 'active' : ''} />
            <img src={xlsxSvg} alt="XLSX Icon" className={isDragActive ? 'active' : ''} />
        </IconWrapper>
        
    </DropZoneComponent>
  )
}