import { ReactElement } from "react";
import { ConnectedComponent } from "react-redux";

import Home from "../Views/Home";
import FileUpload from "../Views/FileUpload";
import Story from "../Views/Story";


type TPage = <T>(props: T) => ReactElement;

interface IRouteObject {
    path: string,
    page: TPage | ConnectedComponent<any, any> // TODO: How to type connectedComponent correctly ?
};

interface IRoutesMap {
    [key: string]: IRouteObject
}

export enum ROUTE_KEYS {
    R_HOME = 'R_HOME',
    R_UPLOAD = 'R_UPLOAD',
    R_STORY = 'R_STORY'
};

export const routesMap: IRoutesMap = {
    [ROUTE_KEYS.R_HOME]: {
        path: '/',
        page: Home
    },
    [ROUTE_KEYS.R_UPLOAD]: {
        path: '/upload',
        page: FileUpload
    },
    [ROUTE_KEYS.R_STORY]: {
        path: '/story',
        page: Story
    }
};

export function getRouteInfo(routeKey: string) {
    return routesMap[routeKey];
}

