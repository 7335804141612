import { UPDATE_NEWS_DATA } from "./types";
import { INewsItem, INewsState, IAction } from "../../ts/redux";

const emptyNewsItem: INewsItem = {
    value: '',
    percentageValue: '',
    date: '',
    source: '',
    timeseriesIdentifier: '',
    link: '',
    icon: ''
}

const defaultState: INewsState = {
    title: '',
    timeseries: [],
    heroTitle: emptyNewsItem,
    villainTitle: emptyNewsItem
};

export const NEWS_REDUCER_KEY:string = 'news';
export function newsReducer(state: INewsState = defaultState, action: IAction): INewsState {
    switch(action.type) {
        case UPDATE_NEWS_DATA:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}