import styled from 'styled-components';

interface TextProps {
    align?: string;
    light?: boolean;
    large?: boolean;   
}

export const Text = styled.p<TextProps>`
    font-family: Roboto;
    font-size: ${(props) => props.large ? '35px' : '16px'};
    font-weight: 400;
    line-height: 1.5;
    color: ${({theme, light}) => light ? theme.colors.monochrome.two : theme.colors.system.text};

    text-align: ${(props) => props.align ? props.align : 'left'};

    strong {
        white-space: nowrap;
    }
`;


export const ErrorText = styled.p`
    display: inline-block;
    padding: 10px 20px;

    font-family: roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.system.error };
    text-align: center;

    background: #e0aab9;

    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.system.error };
`;

