import { createAction } from "../../utils/redux/createAction";
import { XLSX_UPLOAD_START, XLSX_UPLOAD_SUCCESS, XLSX_UPLOAD_ERROR, GENERATE_DEFAULT_FILE_REPORT_START, GENERATE_DEFAULT_FILE_REPORT_ERROR, GENERATE_DEFAULT_FILE_REPORT_SUCCESS, PING_S3_URL, PING_S3_URL_TIMEOUT } from "./types";
import { IAction } from "../../ts/redux";


export interface IFilePayload<T> {
    files: T[]
}
export function xlsxUploadStart<T extends File>(payload: IFilePayload<T>): IAction {
    return createAction(XLSX_UPLOAD_START, payload);
}

export function xlsxUploadSuccess(payload: string): IAction {
    return createAction(XLSX_UPLOAD_SUCCESS, {
        s3PublicUrl: payload
    });
}

export function xlsxUploadError(): IAction {
    return createAction(XLSX_UPLOAD_ERROR);
}

export function generateDefautlFileReportStart(): IAction {
    return createAction(GENERATE_DEFAULT_FILE_REPORT_START);
}

export function generateDefaultFileReportSuccess(payload: string): IAction {
    return createAction(GENERATE_DEFAULT_FILE_REPORT_SUCCESS, {
        s3PublicUrl: payload
    });
}

export function generateDefaultFileReportError(): IAction {
    return createAction(GENERATE_DEFAULT_FILE_REPORT_ERROR);
}

export function pingS3URL(): IAction {
    return createAction(PING_S3_URL);
}

export function pingS3URLTimeout(): IAction {
    return createAction(PING_S3_URL_TIMEOUT);
}