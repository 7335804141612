// export const VERSION: string = 'v1';
// export const ENDPOINT: string = 'https://uat.api.storyteller.investsuite.com/api/'
// export const PING_INTERVAL: number = 2000;
// export const PING_TIMEOUT_AMOUNT = 25;

const API_CONFIG = {
    VERSION: 'v1',
    ENDPOINT:'https://uat.api.storyteller.investsuite.com/api/',
    PING_INTERVAL: 2000,
    PING_TIMEOUT_AMOUNT: 300
};

if (process.env.REACT_APP_BUILD_ENV === 'production') {
    API_CONFIG.ENDPOINT = 'https://api.storyteller.investsuite.com/api/'
}

export default API_CONFIG;