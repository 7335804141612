import React, { ReactElement, MouseEvent, useState } from 'react';

import Quote, { IQuoteProps } from '../../components/Quote';
import FactsheetCard from '../../components/FactsheetCard';
import { Container } from '../../components/Scaffolding/Container';
import { FactsheetCardWrapper, FactsheetComponent, FactsheetCardSlider, ExplanationWrapper, ToggleButtonWrapper, Explanation } from './style';
import { connect } from 'react-redux';
import { TApplicationState } from '../../redux/store';
import { getCurrentPeriod, getPreviousPeriod, getQuote, getToggleButtonValues, getExplanation } from '../../redux/factsheet/selectors';
import ToggleButton from '../../components/ToggleButton';
import { IToggleButtonValues } from '../../ts/general';
import { IFactsheetCard } from '../../ts/components';

interface IFactsheetReduxProps {
    toggleButtonValues: IToggleButtonValues;
    current: IFactsheetCard;
    previous: IFactsheetCard;
    quote: IQuoteProps;
    explanation: string;
}

function Factsheet(props: IFactsheetReduxProps): ReactElement {
    const [isToggled, setIsToggled] = useState(false);

    return (
        <Container>
            <FactsheetComponent>
                <FactsheetCardSlider isToggled={isToggled}>
                    <FactsheetCardWrapper>
                        <ToggleButtonWrapper>
                            <ToggleButton
                                onClick={(event: MouseEvent) => {setIsToggled(!isToggled) }}
                                values={props.toggleButtonValues} 
                                isToggled={isToggled}
                            />
                        </ToggleButtonWrapper>
                        <FactsheetCard muted {...props.previous} />  
                    </FactsheetCardWrapper>
                    <FactsheetCardWrapper>
                        <FactsheetCard {...props.current} />
                    </FactsheetCardWrapper>
                </FactsheetCardSlider>  
                <ExplanationWrapper>
                    <Explanation>
                        <span dangerouslySetInnerHTML={{__html: props.explanation}}></span>
                    </Explanation>
                    <Quote {...props.quote} />    
                </ExplanationWrapper>        
            </FactsheetComponent>
        </Container>
    );
}

function mapStateToProps(state: TApplicationState): IFactsheetReduxProps {
    return {
        toggleButtonValues: getToggleButtonValues(state),
        current: getCurrentPeriod(state),
        previous: getPreviousPeriod(state),
        quote: {
            text: getQuote(state),
        },
        explanation: getExplanation(state),
    };
}

export default connect(mapStateToProps)(Factsheet);