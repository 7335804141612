import React, { ReactElement } from 'react';
import HomeWrapper from '../Home/HomeWrapper';
import Stepper from '../../components/Stepper';
import { Text, ErrorText } from '../../components/Typography/Text';
import DropZone from '../../components/DropZone';
import { TApplicationState } from '../../redux/store';
import { connect } from 'react-redux';
import { getStepperState, getIsLoading, getHasError, getLoadingMessage, getErrorMessage } from '../../redux/fileupload/selectors';
import { DropZoneComponentWrapper } from '../../components/DropZone/style';
import Loader from '../../components/Loader/index';
import { Dispatch } from 'redux';
import { xlsxUploadStart, generateDefautlFileReportStart } from '../../redux/fileupload/actions';
import { DefaultFileButton } from '../Home/style';

interface IFileUploadReduxStateProps {
    stepperState: Array<boolean>;
    isLoading: boolean;
    loadingMessage: string;
    errorMessage: string;
    hasError: boolean;
};

interface IFileUploadReduxDispatchProps {
    proceedWithDefaultFile(): void;
    onDrop<T extends File>(acceptedFiles: T[]): void;
}

type TFileUploadProps = IFileUploadReduxDispatchProps & IFileUploadReduxStateProps;

function FileUpload(props: TFileUploadProps): ReactElement {
    return (
        <HomeWrapper>
            <Stepper steps={props.stepperState}/>
            <Text light large><strong>Drag and drop</strong> or <strong>click</strong> to upload your XLSX-file</Text>
            {props.hasError && <ErrorText>{props.errorMessage}</ErrorText>}
            { props.isLoading
                ? <DropZoneComponentWrapper>
                    <Loader />
                    <Text light>
                        { props.loadingMessage }
                    </Text>
                </DropZoneComponentWrapper>
                : <DropZone onDrop={props.onDrop}></DropZone>
            }
            <br/>
            <DefaultFileButton
                type="button"
                onClick={props.proceedWithDefaultFile}
                disabled={props.isLoading}
            >
                Proceed with the default file
            </DefaultFileButton>
        </HomeWrapper>
    );       
}

function mapStateToProps(state: TApplicationState): IFileUploadReduxStateProps {
    return {
        stepperState: getStepperState(state),
        isLoading: getIsLoading(state),
        loadingMessage: getLoadingMessage(state),
        hasError: getHasError(state),
        errorMessage: getErrorMessage(state)
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        proceedWithDefaultFile: () => {
            dispatch(generateDefautlFileReportStart());
        },
        onDrop: (acceptedFiles: File[]) => {
            dispatch(xlsxUploadStart({files: acceptedFiles}));
        }
    };
}

const ConnectedFileUpload = connect(mapStateToProps, mapDispatchToProps)(FileUpload);

export default function FileUploadPage(): ReactElement {
    return (
        <ConnectedFileUpload />
    )
}