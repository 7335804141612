import styled from 'styled-components';


export const ToggleButtonComponent = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Roboto;
    font-size: 11px;
    color: ${({ theme }) => theme.colors.primary.two };

    border: none;
    background-color: transparent;

    -webkit-appearance: none;
    cursor: pointer;

    &:focus {
        outline: none;
        /* text-decoration: underline; */
    }
`;

interface IToggleIconProps {
    isToggled: boolean;
}

export const ToggleIcon = styled.img<IToggleIconProps>`
    display: block;
    margin-left: 8px;

    transition: all 200ms ease-in-out;
    ${(props) => props.isToggled ? 'transform: rotate(180deg)' : ''}
`;
