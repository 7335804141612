import styled from "styled-components";

export const FundsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacers.vertical.desktop.m};
`;

export const FundsItem = styled.div`
    display: flex;
    width: 40%;
    flex-flow: column;
    justify-content: space-between;

    span {
        font-family: Escrow;
        font-size: 16px;
        line-height: 1.5;

        em {
            font-style: normal;
            font-weight: bold;
        }
    }
`;

export const FundsItemHero = styled(FundsItem)`
    align-items: flex-end;

    border-right: 1px solid ${({ theme }) => theme.colors.primary.two};
    padding-right: ${({ theme }) => theme.spacers.horizontal.desktop.s};

    text-align: right;

    em {
        color: ${({ theme }) => theme.colors.primary.two};
    }

    img {
        margin-bottom: ${({ theme }) => theme.spacers.vertical.desktop.l};
    }
`;

export const FundsItemVillain = styled(FundsItem)`
    align-items: flex-start;

    border-left: 1px solid ${({ theme }) => theme.colors.primary.one};
    padding-left: ${({ theme }) => theme.spacers.horizontal.desktop.s};

    em {
        color: ${({ theme }) => theme.colors.primary.one};
    }

    img {
        margin-top: ${({ theme }) => theme.spacers.vertical.desktop.l};
    }
`;