import React, { ReactElement } from "react";
import { IAttributionSlideComponent } from "../../ts/components";
import { AttributionCategorySlideComponent, AttributionCategorySlideIllustration, AttributionCategorySlideExplanation } from "./style";
import { ATTRIBUTION_ILLUSTRATION_PATH } from "../../config/assets.config";

export default function AttributionCategorySlide({illustrationUrl, value, altMessage, index}: IAttributionSlideComponent): ReactElement {
    return (
        <AttributionCategorySlideComponent reversed={index % 2 !== 0}>
            <AttributionCategorySlideIllustration src={ATTRIBUTION_ILLUSTRATION_PATH + illustrationUrl} alt={altMessage} />
            <AttributionCategorySlideExplanation>
                <span dangerouslySetInnerHTML={{__html: value}}></span>
            </AttributionCategorySlideExplanation>
        </AttributionCategorySlideComponent>
    );    
}