import { IAttributionState } from "../../ts/redux";
import { IAPIResponse } from "../../ts/api";

export function attributionFormatter(payload: IAPIResponse): Promise<IAttributionState> {
    const attributionData = payload.attribution;

    

    const returnValue: IAttributionState = {
        title: attributionData.title,
        factors: attributionData.factor
    }

    return Promise.resolve(returnValue);
}