import { UPDATE_INTRODUCTION_DATA } from "./types";
import { IIntroductionState, IAction } from "../../ts/redux";

const defaultState: IIntroductionState = {
    period: '',
    addressing: '',
    title: '',
    figure: ''
}

export const INTRODUCTION_REDUCER_KEY: string = 'introduction';
export function introductionReducer(state: IIntroductionState = defaultState, action: IAction): IIntroductionState {
    switch (action.type) {
        case UPDATE_INTRODUCTION_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}