import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import { H3 } from '../../components/Typography/headers';
import { DateString } from '../../components/DateString';
import { Container } from '../../components/Scaffolding/Container';
import { ContentWrapperIntroduction, BackgroundDecoration, IntroductionTitle, IntroductionHeader, ImageWrapper} from './style';

import logo from '../../assets/images/logo.svg';
import { TApplicationState } from '../../redux/store';
import { getIntroductionState } from '../../redux/introduction/selectors';
import { INTRODUCTION_ILLUSTRATION_PATH } from '../../config/assets.config';
import { IIntroductionState } from '../../ts/redux';

// This one-on-one mapping is only possible because we are dealing with VERY
// simple state here. Might not be best practice. To investigate. TODO.
function Introduction(props: IIntroductionState): ReactElement {
    return (
        <Container>
            <BackgroundDecoration />
            <IntroductionHeader>
                <img src={logo} alt="InvestSuite"/>
            <DateString datetime="P92D">{props.period}</DateString>
            </IntroductionHeader>
            <ContentWrapperIntroduction>
                <IntroductionTitle bar>{props.title}</IntroductionTitle> 
                <H3>{props.addressing}</H3> 
            </ContentWrapperIntroduction>
            <ImageWrapper>
                <img src={INTRODUCTION_ILLUSTRATION_PATH + props.figure} alt="This should be a description of the illustration."/>
            </ImageWrapper>
        </Container>
    );
}

function mapStateToProps(state: TApplicationState): IIntroductionState {
    return getIntroductionState(state);
}

export default connect(mapStateToProps)(Introduction);