import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Container } from '../../components/Scaffolding/Container';
import { H2 } from '../../components/Typography/headers';
import { TApplicationState } from '../../redux/store';
import { getTitle, getHeroNewsItem, getVillainNewsItem, getTimeseries } from '../../redux/news/selectors';
import Chart, { IChartProps } from '../../components/Chart';

type TNewsReduxProps = IChartProps & {
    title: string;
};

function News(props: TNewsReduxProps): ReactElement {
    return (
        <Container>
            <H2 bar><span dangerouslySetInnerHTML={{__html: props.title}}></span></H2>
            <Chart
                heroNewsItem={props.heroNewsItem}
                villainNewsItem={props.villainNewsItem}
                timeseries={props.timeseries}
            />
        </Container>
    );
}

function mapStateToProps(state: TApplicationState): TNewsReduxProps {
    return {
        title: getTitle(state),
        heroNewsItem: getHeroNewsItem(state),
        villainNewsItem: getVillainNewsItem(state),
        timeseries: getTimeseries(state)
    };
}

export default connect(mapStateToProps)(News);