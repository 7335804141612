import styled from 'styled-components';
import { H4 } from '../Typography/headers';

export const FactsheetCardComponent = styled.div`
    position: relative;

    display: block;
    width: 100%;
    
    padding: 
        ${({theme}) => theme.spacers.vertical.desktop.m}
        ${({theme}) => theme.spacers.horizontal.desktop.xs}
        ${({theme}) => theme.spacers.vertical.desktop.xs};

    background-color: ${({theme}) => theme.colors.monochrome.six};
    border-top: 4px solid ${({theme}) => theme.colors.primary.one};

    &.muted ${H4} {
        color: ${({theme}) => theme.colors.monochrome.four};
    }
`;

export const ValueWrapper = styled.div`
    display: flex;
    width: 100;
    justify-content: space-between;

    > div, > p {
        width: 50%;
    }
`;

export const LargeValue = styled.p`
    display: block;
    margin: ${({theme}) => theme.spacers.vertical.desktop.xs} 0;

    font-family: escrow;
    font-size: 50px;
    font-weight: 700;
    line-height: 0.8;
    color: ${({theme}) => theme.colors.primary.two};

    .muted & {
        color: ${({theme}) => theme.colors.monochrome.seven};
    }
`;

export const RegularValue = styled.p`
    display: block;
    margin: 0 0 16px;

    font-family: Roboto;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.16;
    color: ${({theme}) => theme.colors.system.text};

    .muted & {
        color: ${({theme}) => theme.colors.monochrome.four};
    }
`;

