import styled from 'styled-components';

export const StepperComponent = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

interface IStepperItemProps {
    active?: boolean;
}

export const StepperDot = styled.div<IStepperItemProps>`
    position: relative;
    
    display: block;
    width: 16px;
    height: 16px;

    border: 2px solid ${({ theme, active }) => active ? theme.colors.primary.one : theme.colors.monochrome.two };
    border-radius: 100%;

    background-color: ${({ theme, active }) => active ? theme.colors.primary.one : 'transparent' };

    transition: all 200ms ease-in-out;
`;



export const StepperLine = styled.div<IStepperItemProps>`
    display: block;
    width: 50px;
    height: 0;

    border: 2px solid ${({ theme, active }) => active ? theme.colors.primary.one : theme.colors.monochrome.two };

    transition: all 200ms ease-in-out;
`;