import { FILE_UPLOAD_REDUCER_KEY } from "./reducer";
import { TApplicationState } from "../store";
import { TReducerSelector } from "../../ts/general";
import { IFileUploadState } from "../../ts/redux";

const reducerState: TReducerSelector<IFileUploadState> = (state: TApplicationState) => state[FILE_UPLOAD_REDUCER_KEY];

export const getStepperState = (state: TApplicationState) => reducerState(state).stepperState;

export const getIsLoading = (state:TApplicationState) => reducerState(state).isLoading;

export const getHasError = (state: TApplicationState) => reducerState(state).hasError;

export const getLoadingMessage = (state: TApplicationState): string => {
    const current = reducerState(state);

    if (current.isUploading) {
        return 'Uploading file';
    } else if (current.isRunningAlgorithm) {
        return 'Running algorithm';
    } else if (current.isCreatingStory) {
        return 'Creating Story';
    }

    return 'Loading...';
}

export const getErrorMessage = (state: TApplicationState): string => {
    const current = reducerState(state);

    if (current.xlsxError) {
        return 'Woops! Something went wrong. Please check your xlsx file and try again.';
    } else if (current.timeoutError) {
        return 'Woops! Timed out while trying to generate the report. Please try again.';
    }

    return 'Something went wrong. Please try again.'
}

export const getS3Url = (state:TApplicationState) => reducerState(state).s3PublicUrl;

export const getPingTries = (state:TApplicationState) => reducerState(state).pingTries;