import { createElement, ReactElement } from "react";
// import Home from "../Home";
import { connect } from "react-redux";
import { TApplicationState } from "../../redux/store";
import { getPage } from "../../redux/location/selectors";

interface IAppRouterReduxProps {}

function AppRouter(props: any): ReactElement {
    return createElement(props.page);
}
// class AppRouter extends Component {
//     props = this.props;

//     renderPage(): ReactElement {
//         return createElement(Home);
//     }

//     render(): ReactElement {
//         return this.renderPage();
//     }
// }

export default connect(mapStateToProps)(AppRouter);

function mapStateToProps(state: TApplicationState) {
    return {
        page: getPage(state)
    };
}