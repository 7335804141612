import { UPDATE_FACTSHEET_DATA } from "./types";
import { IAction, IFactsheetState } from "../../ts/redux";

const emptyTV = {
    title: '',
    value: ''
};

const emptyTVP = {
    title: '',
    value: '',
    percentage: ''
};

const defaultState: IFactsheetState = {
    information: '',
    comparison: {
        show: 'Show previous quarter',
        hide: 'Hide previous quarter',
    },
    current: {
        portfolio: emptyTV,
        inceptionGrowth: emptyTVP,
        periodGrowth: emptyTVP,
        dividends: emptyTV,
        coupons: emptyTV,
        deposits: emptyTV,
        withdrawals: emptyTV,
    },
    previous: {
        portfolio: emptyTV,
        inceptionGrowth: emptyTVP,
        periodGrowth: emptyTVP,
        dividends: emptyTV,
        coupons: emptyTV,
        deposits: emptyTV,
        withdrawals: emptyTV,
    },
    quote: '',
    explanation: ''
}

export const FACTSHEET_REDUCER_KEY: string = 'factsheet';
export function factsheetReducer(state: IFactsheetState = defaultState, action: IAction): IFactsheetState {
    switch (action.type) {
        case UPDATE_FACTSHEET_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}