import styled from 'styled-components';
import { IStyledAttributionThumbComponent } from '../../ts/components';

export const AttributionCategoryThumbComponent = styled.div<IStyledAttributionThumbComponent>`
    position: relative;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    min-height: 130px;
    align-items: center;
    padding: ${({ theme }) => theme.spacers.vertical.desktop.xs } ${({ theme }) => theme.spacers.horizontal.desktop.xs };

    color: ${({theme, active}) => active ? theme.colors.system.text : theme.colors.monochrome.four };
    
    border-bottom: 1px solid ${({ theme }) => theme.colors.monochrome.nine };
    cursor: pointer;

    &:after {
        content: ' ';

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        display: ${(props) => props.active ? 'block' : 'none'};
        height: 4px;

        background-color: ${({ theme }) => theme.colors.primary.one };
    }
`;

export const AttributionValue = styled.p`
    display: block;
    margin: 0;
    padding: 0;

    font-family: escrow;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
`;

export const AttributionName = styled.p`
    display: block;
    margin-bottom: 12px;
    padding: 0;

    font-family: Escrow;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
`;