import React, { ReactElement } from 'react';

import { QuoteComponent, QuoteText, QuoteArrow } from './style';
import { ContentWrapper } from '../Scaffolding/ContentWrapper';
import quoteArrow from '../../assets/images/quote-arrow.png';

export interface IQuoteProps {
    text: string,
}

export default function Quote({text}: IQuoteProps): ReactElement {
    return (
        <QuoteComponent>
            <ContentWrapper>
                <QuoteText><span dangerouslySetInnerHTML={{__html: text}}></span></QuoteText>
            </ContentWrapper>
            <QuoteArrow src={quoteArrow} />
        </QuoteComponent>
    );
}