import styled from 'styled-components';

export const FactsheetComponent = styled.div` // overflow;
    position: relative;
    
    display: flex;
    align-items: flex-start;

    overflow-x: hidden;
`;

export const ToggleButtonWrapper = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.spacers.vertical.desktop.xs };
    right: ${({ theme }) => theme.spacers.horizontal.desktop.xs};

    z-index: ${({ theme }) => theme.layers.overBaseLayer };
`;

export const FactsheetCardWrapper = styled.div`
    width: 50%;
    flex-shrink: 0;

    & + & {
        margin-left: 2px;
    }
`;

interface IFactsheetCardSliderProps {
    isToggled: boolean;
}

export const FactsheetCardSlider = styled.div<IFactsheetCardSliderProps>` // Slider
    position: realtive;
  
    display: flex;
    flex-shrink: 0;
    width: 100%;
    
    transform: ${({isToggled}) => isToggled ? 'translate3d(0, 0, 0)' : 'translate3d(calc(-50% - 1px), 0, 0)'};
    transition: all 200ms ease-in-out;

    z-index: ${({theme}) => theme.layers.overBaseLayer};
`;

export const ExplanationWrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: 50%;
    height: 100%;
    align-items: flex-end;
    flex-shrink: 0;
    
    transform: translateX(-100%);
`;

export const Explanation = styled.div`
    max-width: 385px;
    margin-left: 40px;

    font-family: Escrow;
    font-size: 24px;

    em {
        font-style: normal;
        font-weight: bold;
    }
`;