import React, { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';

import { Container } from '../../components/Scaffolding/Container';
import { AttributionTitle, AttributionCategoryThumbContainer, AttributionCategorySlidesContainer, AttributionSlideButtonLeft, AttributionSlideButtonRight, AttributionSlidesWrapper, AttributionSlidesSlidingContainer } from './style';
import AttributionCategoryThumb from '../../components/AttributionCategoryThumb';
import AttributionCategorySlide from '../../components/AttributionCategorySlide';
import { TApplicationState } from '../../redux/store';
import { getTitle, getAttributionCategories } from '../../redux/attribution/selectors';
import { IAttributionComponent } from '../../ts/components';
import chevronActive from '../../assets/icons/chevron-large--active.svg';
import chevronDisabled from '../../assets/icons/chevron-large--disabled.svg';


interface IAttributionReduxProps {
    title: string,
    attributionCategories: IAttributionComponent[]
}

type TAttributionProps = IAttributionReduxProps & {
    theme: any // TODO This should be defaultTheme type but doens't work for color prop.
};

function Attribution(props: TAttributionProps): ReactElement {
    const [activeSlide, setActiveSlide] = useState(0);

    const attributionCategoryThumbs = props.attributionCategories.map((category: IAttributionComponent, index: number) => {
        return (
            <AttributionCategoryThumb
                key={category.name + 'thumb'}
                index={index}
                activeIndex={activeSlide}
                name={category.name}
                value={category.thumbValue}
                clickHandler={() => setActiveSlide(index)}
            />
        );;
    });

    const attributionCategorySlides = props.attributionCategories.map((category: IAttributionComponent, index: number) => {
        return (
            <AttributionCategorySlide
                key={category.name + 'slide'}
                illustrationUrl={category.illustrationUrl}
                value={category.slideValue}
                altMessage={category.name}
                index={index}
            />
        )
    });

    return (
        <Container>
            <AttributionTitle bar barColor={props.theme.colors.primary.two} dangerouslySetInnerHTML={{ __html: props.title }}></AttributionTitle>
            <AttributionCategoryThumbContainer>
                {attributionCategoryThumbs}
            </AttributionCategoryThumbContainer>
            <AttributionCategorySlidesContainer>
                <AttributionSlideButtonLeft
                    onClick={() => {
                        let newSlideNumber:number = activeSlide - 1;
                        setActiveSlide(Math.max(0, newSlideNumber))
                    }}
                >
                    <img src={
                        activeSlide === 0 
                            ?   chevronDisabled
                            :   chevronActive
                    } alt="Previous"/>
                </AttributionSlideButtonLeft>
                <AttributionSlidesWrapper>
                    <AttributionSlidesSlidingContainer slidingFactor={activeSlide}>
                        {attributionCategorySlides}
                    </AttributionSlidesSlidingContainer>
                </AttributionSlidesWrapper>
                <AttributionSlideButtonRight
                    onClick={() => {
                        let newSlideNumber:number = activeSlide + 1;
                        setActiveSlide(Math.min(attributionCategorySlides.length - 1, newSlideNumber));
                    }}
                >
                    <img src={
                        activeSlide === attributionCategorySlides.length - 1 
                            ?   chevronDisabled
                            :   chevronActive
                    } alt="Next"/>
                </AttributionSlideButtonRight>
            </AttributionCategorySlidesContainer>
        </Container>
    );
}

function mapStateToProps(state: TApplicationState): IAttributionReduxProps {
    return {
        title: getTitle(state),
        attributionCategories: getAttributionCategories(state),
    }
}

export default connect(mapStateToProps)(withTheme(Attribution));