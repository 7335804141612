import styled from 'styled-components';
import {DropzoneRootProps} from 'react-dropzone';

type TDropZoneProps = DropzoneRootProps & {
    active?: boolean
}

export const DropZoneComponentWrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: 680px;
    height: 230px;
    align-items: center;
    justify-content: center;

    border: 1px solid ${({ theme }) => theme.colors.monochrome.two };
`;


export const DropZoneComponent = styled(DropZoneComponentWrapper)<TDropZoneProps>`
    background: ${({active}) => active ? 'rgba(255,255,255,.2)' : ''};

    transition: all 150ms ease-in-out;
`;

export const IconWrapper = styled.div`
    position: relative;
    
    display: block;

    img {
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        opacity: 0;

        transition: all 150ms ease-in-out;
        transform: translate3d(-50%, -50%, 0);

        &.active {
            opacity: 1;
        }
    }
`;
