import { NEWS_REDUCER_KEY } from "./reducer";
import { TApplicationState } from "../store";
import { getValueType } from "../story/selectors";
import { INewsState, ITimeSeriesObject } from "../../ts/redux";
import { TReducerSelector } from "../../ts/general";

const reducerState: TReducerSelector<INewsState> = (state: TApplicationState) => state[NEWS_REDUCER_KEY];

export const getTitle = (state: TApplicationState) => reducerState(state).title;

export const getTimeseries = (state: TApplicationState) => reducerState(state).timeseries;

export const timeseriesValueLookup = (state: INewsState, identifier: string): number => {
    const timeseriesItem: ITimeSeriesObject | undefined = state.timeseries.find((item: ITimeSeriesObject) => item.date === identifier);
    return timeseriesItem ? timeseriesItem.value : 0;
}

export const getHeroNewsItem = (state: TApplicationState) => {
    const rs: INewsState = reducerState(state);

    return {
        value: rs.heroTitle[getValueType(state)],
        date: rs.heroTitle.date,
        source: rs.heroTitle.source,
        icon: rs.heroTitle.icon,
        timeseriesIdentifier: rs.heroTitle.timeseriesIdentifier,
        timeseriesValue: timeseriesValueLookup(rs, rs.heroTitle.timeseriesIdentifier),
        link: rs.heroTitle.link
    };
}

export const getVillainNewsItem = (state: TApplicationState) => {
    const rs: INewsState = reducerState(state);

    return {
        value: rs.villainTitle[getValueType(state)],
        date: rs.villainTitle.date,
        source: rs.villainTitle.source,
        icon: rs.villainTitle.icon,
        timeseriesIdentifier: rs.villainTitle.timeseriesIdentifier,
        timeseriesValue: timeseriesValueLookup(rs, rs.villainTitle.timeseriesIdentifier),
        link: rs.villainTitle.link
    };
}