import { TApplicationState } from "../store";
import { STORY_REDUCER_KEY, IStoryState, ValueType } from './reducer';
import { TReducerSelector } from "../../ts/general";

const reducerState: TReducerSelector<IStoryState> = (state: TApplicationState) => state[STORY_REDUCER_KEY];

export const getValueType = (state: TApplicationState) => reducerState(state).valueType;

export const getValueTypeAsBoolean = (state: TApplicationState) => reducerState(state).valueType === ValueType.ABSOLUTE;

export const getHasLoadedStory = (state: TApplicationState) => reducerState(state).hasLoadedStory;