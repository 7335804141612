import {call, takeLatest, put, select, delay} from 'redux-saga/effects';
import { XLSX_UPLOAD_START, GENERATE_DEFAULT_FILE_REPORT_START, PING_S3_URL } from './types';
import { xlsxUploadSuccess, IFilePayload, xlsxUploadError, generateDefaultFileReportError, generateDefaultFileReportSuccess, pingS3URL, pingS3URLTimeout } from './actions';
import { createStory } from '../story/actions';
import { loadStoryData, loadDefaultStoryData, pingGeneratedStoryUrl } from '../../services/api';
import { IAPIResponse, IAPIIntermediateResponse } from '../../ts/api';
import { IFileAction } from '../../ts/redux';
import { intermediateResponseFormatter } from '../../services/formatters/intermediateResponse.formatter';
import { getS3Url, getPingTries } from './selectors';
import API_CONFIG from '../../config/api.config';


function* uploadFile<T extends File & {path: string}>({payload}: {payload: IFilePayload<T>}) {
    
    try {
        const intermediateResponse: IAPIIntermediateResponse = yield call(loadStoryData, payload);
        const url: string = yield call(intermediateResponseFormatter, intermediateResponse);
        yield put(xlsxUploadSuccess(url));
        yield put(pingS3URL());
    } catch (e) {
        yield put(xlsxUploadError());
    }
    
}

function* generateDefaultReport() {
    try {
        const intermediateResponse: IAPIIntermediateResponse = yield call(loadDefaultStoryData);
        const url: string = yield call(intermediateResponseFormatter, intermediateResponse);
        yield put(generateDefaultFileReportSuccess(url));
        yield put(pingS3URL());
    } catch (e) {
        yield put(generateDefaultFileReportError());
    }
}

function* pingS3URLForReport() {
    const url: string = yield select(getS3Url);
    try {
        const response: IAPIResponse = yield call(pingGeneratedStoryUrl, url);    
        yield put(createStory(response));
    } catch(e) {
        const tries: number = yield select(getPingTries);
        if (tries < API_CONFIG.PING_TIMEOUT_AMOUNT) {
            yield delay(API_CONFIG.PING_INTERVAL);
            yield put(pingS3URL());
        } else {
            yield put(pingS3URLTimeout());
        }
    }
}

export function* fileUploadSagas() {
    yield takeLatest(XLSX_UPLOAD_START, <T extends File & { path: string }>(action: IFileAction<T>) => uploadFile(action));
    yield takeLatest(GENERATE_DEFAULT_FILE_REPORT_START, generateDefaultReport);
    yield takeLatest(PING_S3_URL, pingS3URLForReport);
}