import styled from 'styled-components';
import { ILabelViewbox } from '.';

export const ChartComponent = styled.div`
    position: relative;

    display: block;
    width: 100%;
    height: 450px;
    /* padding: 50px 0; */
    margin: ${({ theme }) => theme.spacers.vertical.desktop.s } 0;

    background: ${({ theme }) => theme.colors.monochrome.six };
`;

export const Source = styled.strong`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 8px;

    font-family: Roboto;
    font-size: 11px;
    line-height: 1.3;

    img {
        display: inline-block;
        margin-left: 8px;
    }
`;

export const CustomLabelComponent = styled.a<ILabelViewbox>`
    position: absolute;
    top: ${(props) => `${props.y}px`};
    left: ${(props) => `${props.x}px`};

    display: block;
    width: 100%;
    max-width: 440px;
    padding: 0 16px;

    font-family: escrow;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.system.text };
    text-align: ${(props) => props.x > 512 ? 'right' : 'left'};
    text-decoration: none;

    z-index: ${({ theme }) => theme.layers.overBaseLayer };

    em {
        font-style: normal;
        white-space: nowrap;
    }

    ${Source} {
        flex-flow: ${(props) => props.x > 512 ? 'row' : 'row-reverse'};
        justify-content: ${(props) => props.x > 512 ? 'flex-end' : 'flex-start'};

        img {
            ${(props) => props.x > 512 ? 'margin-left: 8px' : 'margin-right: 8px'};
        }
    }
`;

export const HeroLabel = styled(CustomLabelComponent)`
    padding-top: 16px;
    padding-bottom: ${({ theme }) => theme.spacers.vertical.desktop.s };

    border-right: 1px solid ${({ theme }) => theme.colors.primary.two };

    ${(props) => props.x > 512 
        ? `border-right: 1px solid ${props.theme.colors.primary.two };`
        : `border-left: 1px solid ${props.theme.colors.primary.two };`
    };
    
    transform: ${(props) => props.x > 512 ? 'translate3d(calc(-100% + 5px), -100%, 0)' : 'translate3d(0, -100%, 0)'};

    em {
        color: ${({ theme }) => theme.colors.primary.two };
    }
`;

export const VillainLabel = styled(CustomLabelComponent)`
    padding-top: ${({ theme }) => theme.spacers.vertical.desktop.s };
    padding-bottom: 16px;

    ${(props) => props.x > 512
        ? `border-right: 1px solid ${props.theme.colors.primary.one };`
        : `border-left: 1px solid ${props.theme.colors.primary.one };`
    };

    transform: ${(props) => props.x > 512 ? 'translate3d(calc(-100% + 5px), 10px, 0)' : 'translate3d(5px, 10px, 0)'};

    em {
        color: ${({ theme }) => theme.colors.primary.one };
    }
`;

export const MonthLabelComponent = styled.text`
    display: block;
    font-family: escrow;
    font-size: 50px;
    font-weight: 700;
    fill: ${({ theme }) => theme.colors.monochrome.two };

    transform: translate(-40px, 12%);
`;





