import React, { ReactElement } from 'react';
import { ToggleButtonComponent, ToggleIcon } from './style';
import chevron from '../../assets/icons/chevron.svg';
import { IToggleButtonValues, TClickHandler } from '../../ts/general';

export interface IToggleButtonProps {
    values: IToggleButtonValues,
    isToggled: boolean,
    onClick: TClickHandler,
};

export default function ToggleButton({isToggled, values, onClick: clickHandler}: IToggleButtonProps): ReactElement {
    return (
        <ToggleButtonComponent as='button' onClick={clickHandler}>
            { isToggled ? values.hide : values.show }s
            <ToggleIcon isToggled={isToggled} src={chevron} alt={ isToggled ? values.hide : values.show } />
        </ToggleButtonComponent>
    );
}