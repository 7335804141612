import React, { ReactElement } from 'react';
import { SwitchComponent, Thumb } from './style';

import percentageGrey from '../../assets/icons/percentage_grey.svg';
import euroGrey from '../../assets/icons/euro_grey.svg';
import percentage from '../../assets/icons/percentage.svg';
import euro from '../../assets/icons/euro.svg';
import { TClickHandler } from '../../ts/general';

interface ISwitchProps {
    isOn: boolean;
    onClick: TClickHandler
}

export default function Switch({isOn, onClick}: ISwitchProps): ReactElement {
    // const [isOn, setIsOn] = useState(props.isOn);

    return (
        <SwitchComponent onClick={(e) => { 
            // setIsOn(!isOn);
            onClick(e);
        }}>
            <img src={percentageGrey} alt="Percentage"/>
            <img src={euroGrey} alt="Euro"/>
            <Thumb isOn={isOn}>
                <img src={percentage} alt="Percentage" className={!isOn ? 'on' : ''} />
                <img src={euro} alt="Euro" className={isOn ? 'on' : ''} />
            </Thumb>
        </SwitchComponent>
    );
}