import styled from 'styled-components';

export const DownloadButtonComponent = styled.a`
    display: inline-block;
    padding: 8px ${({ theme }) => theme.spacers.horizontal.desktop.xs };

    font-family: roboto;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.monochrome.two };
    text-decoration: none;

    border: 1px solid ${({ theme }) => theme.colors.monochrome.two };
    background: transparent;

    transition: all 200ms ease-in-out;

    &:hover {
        background: rgba(255,255,255, .2);
    }
`;


