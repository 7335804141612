import { UPDATE_ATTRIBUTION_DATA } from "./types";
import { IAttributionState, IAction } from "../../ts/redux";

const emptyVPV = {
    value: '',
    percentageValue: '',
}

const defaultState: IAttributionState = {
    title: emptyVPV,
    factors: []
}

export const ATTRIBUTION_REDUCER_KEY: string = 'attribution';
export function attributionReducer(state: IAttributionState = defaultState, action: IAction): IAttributionState {
    switch (action.type) {
        case UPDATE_ATTRIBUTION_DATA:
            return {
                ...state,
                ...action.payload
            };
        default: 
            return state;
    }
}