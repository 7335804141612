import styled from "styled-components";
import { IStyledAttributionSlideComponent } from "../../ts/components";

export const AttributionCategorySlideComponent = styled.div<IStyledAttributionSlideComponent>`
    display: flex;
    width: 764px;
    flex-flow: ${(props) => props.reversed ? 'row-reverse' : 'row'};
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    & + & {
        margin-left: 20px;
    }
`;

export const AttributionCategorySlideIllustration = styled.img`
    display: block;
    width: 240px;
    margin-right: ${({ theme }) => theme.spacers.horizontal.desktop.s };
`;

export const AttributionCategorySlideExplanation = styled.p`
    max-width: 450px;

    font-family: Escrow;
    font-size: 16px;
    line-height: 1.5;

    em {
        font-style: normal;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary.two };
    }
`;