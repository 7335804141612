import React, { ReactElement } from 'react';
import { H4 } from '../Typography/headers';
import { ContentWrapper } from '../Scaffolding/ContentWrapper';
import { FactsheetCardComponent, LargeValue, RegularValue, ValueWrapper } from './style';
import { HorizontalLine } from '../Scaffolding/HorizontalLine';
import { IFactsheetCard } from '../../ts/components';

export interface IFactsheetCardProps extends IFactsheetCard {
    muted?: boolean,
}

export default function FactsheetCard(props: IFactsheetCardProps): ReactElement {
    return (
        <FactsheetCardComponent className={props.muted ? 'muted' : ''}>
            <ContentWrapper>
                <H4 bold>{props.portfolio.title}</H4>
                <LargeValue>{props.portfolio.value}</LargeValue>

                <HorizontalLine />
                
                <H4>{props.inceptionGrowth.title}</H4>    
                <ValueWrapper>
                    <RegularValue>{props.inceptionGrowth.value}</RegularValue>
                    <RegularValue>{props.inceptionGrowth.percentageValue}</RegularValue>
                </ValueWrapper>
                
                
                <HorizontalLine />

                <H4>{props.periodGrowth.title}</H4>
                <ValueWrapper>
                    <RegularValue>{props.periodGrowth.value}</RegularValue>
                    <RegularValue>{props.periodGrowth.percentageValue}</RegularValue>
                </ValueWrapper>
                
                
                <HorizontalLine />

                <ValueWrapper>
                    <div>
                        <H4>{props.dividends.title}</H4>
                        <RegularValue>{props.dividends.value}</RegularValue>
                    </div>
                
                    <div>
                        <H4>{props.coupons.title}</H4>
                        <RegularValue>{props.coupons.value}</RegularValue>
                    </div>
                </ValueWrapper>
                
                
                <HorizontalLine />

                <ValueWrapper>
                    <div>
                        <H4>{props.deposits.title}</H4>
                        <RegularValue>{props.deposits.value}</RegularValue>
                    </div>
                    
                    <div>
                        <H4>{props.withdrawals.title}</H4>
                        <RegularValue>{props.withdrawals.value}</RegularValue>
                    </div>
                </ValueWrapper>
                
            </ContentWrapper>
        </FactsheetCardComponent>
    );
}