import React, { ReactElement, useState } from "react";
import { withTheme } from 'styled-components';
import moment from 'moment';
import {LineChart, Line, YAxis, CartesianGrid, ReferenceDot, ReferenceLine, XAxis, ResponsiveContainer} from 'recharts';
import { ChartComponent, HeroLabel, VillainLabel, Source, MonthLabelComponent } from "./style";
import { ITimeSeriesObject } from "../../ts/redux";
import { NEWS_ILLUSTRATION_PATH } from "../../config/assets.config";

interface IMonthsObject {
    [key: string]: string
}

interface IChartNewsItem {
    value: string;
    date: string;
    source: string;
    timeseriesIdentifier: string;
    timeseriesValue: number;
    link: string;
    icon: string;
}

export interface IChartProps {
    heroNewsItem: IChartNewsItem
    villainNewsItem: IChartNewsItem,
    timeseries: Array<ITimeSeriesObject>
}

type TChartProps = IChartProps & {
    theme: any // TODO: Should be defaulttheme but doens't work.
};

export interface ILabelViewbox {
    x: Number,
    y: Number,
    width: Number,
    height: Number
}

const defaultLabelViewBoxSettings: ILabelViewbox = {
    x: 0,
    y: 0,
    width: 10,
    height: 10
};

function MonthLabel(props: any): ReactElement {

    return (
        <MonthLabelComponent y={props.viewBox.y + props.viewBox.height} x={props.viewBox.x - props.offset}>{props.month}</MonthLabelComponent>
    );
}

function Chart(props: TChartProps): ReactElement {

    const [heroLabelViewbox, setHeroLabelViewbox] = useState<ILabelViewbox>(defaultLabelViewBoxSettings);
    const [villainLabelViewBox, setVillainLabelViewBox] = useState<ILabelViewbox>(defaultLabelViewBoxSettings);

    const months:IMonthsObject = {};

    props.timeseries.forEach((points) => {
        const month = moment(points.date).format('MMM');
        if (!Object.prototype.hasOwnProperty.call(months, month)) {
            months[month] = points.date;
        }
    });
    const monthKeys: Array<string> = Object.keys(months);
    monthKeys.shift();
    const referenceLines = monthKeys.map((month: string) => {
        return (
            <ReferenceLine 
                key={month} 
                x={months[month]} 
                stroke={props.theme.colors.monochrome.two} 
                strokeWidth="2" 
                isFront={false}
                label={<MonthLabel month={month} />}
            />
                
        );
    })

    function heroLabelFunction(viewbox: ILabelViewbox) {
        if (heroLabelViewbox.x === 0) {
            setHeroLabelViewbox(viewbox);
        }

        return null;
    }

    function villainLabelFunction(viewbox: ILabelViewbox) {
        if (villainLabelViewBox.x === 0) {
            setVillainLabelViewBox(viewbox);
        }

        return null;
    }


    return (
        <ChartComponent>
            <HeroLabel {...heroLabelViewbox} href={props.heroNewsItem.link} target="_blank" rel="noopener noreferrer">
                <Source><span>{props.heroNewsItem.date} - {props.heroNewsItem.source}</span><img src={NEWS_ILLUSTRATION_PATH + props.heroNewsItem.icon} alt={props.heroNewsItem.source} /></Source>
                <span dangerouslySetInnerHTML={{__html: props.heroNewsItem.value}}></span>
            </HeroLabel>
            <VillainLabel {...villainLabelViewBox} href={props.villainNewsItem.link} target="_blank" rel="noopener noreferrer">
                <Source><span>{props.villainNewsItem.date} - {props.villainNewsItem.source}</span><img src={NEWS_ILLUSTRATION_PATH + props.villainNewsItem.icon} alt={props.villainNewsItem.source} /></Source>
                <span dangerouslySetInnerHTML={{__html: props.villainNewsItem.value}}></span>
            </VillainLabel>
            <ResponsiveContainer>
                <LineChart
                    data={props.timeseries}
                    margin={{
                        top: 150, right: 15, left: 50, bottom: 150,
                    }}
                >
                    <CartesianGrid vertical={false} />
                    <YAxis domain={['auto', 'dataMax']} stroke={props.theme.colors.monochrome.nine} tickLine={false} axisLine={false} />
                    <XAxis dataKey="date" hide />
                    {referenceLines}
                    <Line type="monotone" dataKey="value" stroke={props.theme.colors.monochrome.seven} dot={false} strokeWidth={2} />
                    <ReferenceDot 
                        x={props.heroNewsItem.timeseriesIdentifier} 
                        y={props.heroNewsItem.timeseriesValue} 
                        r={5} 
                        fill={props.theme.colors.primary.two}
                        stroke={props.theme.colors.monochrome.six} 
                        strokeWidth={2}
                        label={(props) => heroLabelFunction(props.viewBox)}
                        isFront={true} 
                    />
                    <ReferenceDot 
                        x={props.villainNewsItem.timeseriesIdentifier} 
                        y={props.villainNewsItem.timeseriesValue} 
                        r={5} 
                        fill={props.theme.colors.primary.one} 
                        stroke={props.theme.colors.monochrome.six} 
                        strokeWidth={2}
                        label={(props) => villainLabelFunction(props.viewBox)}
                        isFront={true} 
                    />
                </LineChart>
            </ResponsiveContainer>
        </ChartComponent>
    );
}

export default withTheme(Chart);