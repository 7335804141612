import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
//   Switch,
//   Route,
} from "react-router-dom";

import store from '../../redux/store';
// import Story from '../Story/index';

import { StoryTellerTheme } from '../../styles/theme';
import '../../styles/App.css';
// import Home from '../Home';
// import FileUpload from '../FileUpload';
import AppRouter from './AppRouter';

function App() {
  return (
    <Provider store={store} >
      <ThemeProvider theme={StoryTellerTheme} >
        <Router>
          <AppRouter />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
