import styled from 'styled-components';

interface DateStringProps {
    readonly small?: boolean;
    readonly datetime: string;
};

export const DateString = styled.time<DateStringProps>`
    font-family: Roboto;
    font-size: ${(props) => props.small ? '11px' : '17px'};
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.system.text}
`;