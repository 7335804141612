import { XLSX_UPLOAD_START, XLSX_UPLOAD_SUCCESS, XLSX_UPLOAD_ERROR, GENERATE_DEFAULT_FILE_REPORT_START, GENERATE_DEFAULT_FILE_REPORT_SUCCESS, PING_S3_URL, GENERATE_DEFAULT_FILE_REPORT_ERROR, PING_S3_URL_TIMEOUT } from "./types";
import { CREATE_STORY_COMPLETE } from "../story/types";
import { IAction, IFileUploadState } from "../../ts/redux";

const defaultState: IFileUploadState = {
    stepperState: [true, true, false],
    isLoading: false,
    isUploading: false,
    isRunningAlgorithm: false,
    isCreatingStory: false,
    hasError: false,
    xlsxError: false,
    timeoutError: false,
    s3PublicUrl: '',
    pingTries: 0,
}

export const FILE_UPLOAD_REDUCER_KEY = 'fileupload';
export function fileUploadReducer(state:IFileUploadState = defaultState, action: IAction): IFileUploadState {
    switch(action.type) {
        case XLSX_UPLOAD_START:
        case GENERATE_DEFAULT_FILE_REPORT_START:
            return {
                ...state,
                stepperState: [true, true, true],
                isLoading: true,
                isUploading: true,
                hasError: false,
                xlsxError: false,
                timeoutError: true,
                pingTries: 0
            };
        case GENERATE_DEFAULT_FILE_REPORT_SUCCESS:
        case XLSX_UPLOAD_SUCCESS:
            return {
                ...state,
                isUploading: false,
                isRunningAlgorithm: true,
                isCreatingStory: true,
                ...action.payload
            };
        case GENERATE_DEFAULT_FILE_REPORT_ERROR:
        case XLSX_UPLOAD_ERROR:
            return {
                ...state,
                stepperState: [true, true, false],
                isLoading: false,
                isUploading: false,
                hasError: true,
                xlsxError: true,
            }
        case CREATE_STORY_COMPLETE: 
            return {
                ...state,
                stepperState: [true, true, false],
                isLoading: false,
                isCreatingStory: false
            };
        case PING_S3_URL: 
            return {
                ...state,
                pingTries: state.pingTries+=1
            }
        case PING_S3_URL_TIMEOUT:
            return {
                ...state,
                stepperState: [true, true, false],
                isLoading: false,
                isUploading: false,
                hasError: true,
                timeoutError: true
            }
        default: 
            return state;
    }
}