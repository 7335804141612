import styled from 'styled-components';
import { H2, H3 } from '../../components/Typography/headers';
import { IStyledAttributionSlidesSlidingContainer } from '../../ts/components';

export const AttributionLabel = styled(H3)`
    font-family: Roboto;
    font-size: 11px;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.primary.one};
`;

export const AttributionTitle = styled(H2)`
    max-width: 50%;
    margin-top: ${({theme}) => theme.spacers.vertical.desktop.xs};

    em {
        font-style: normal;
        color: ${({theme}) => theme.colors.primary.one};
        white-space: nowrap;
    }
`;

export const AttributionDescription = styled.div`
    display: block;
    max-width: 50%;
`;

export const AttributionCategoryThumbContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: ${({ theme }) => theme.spacers.vertical.desktop.s };
`;

export const AttributionCategorySlidesContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 400px;
`;

export const AttributionSlideButton = styled.div`
    display: flex;
    width: 80px;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-out;

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.monochrome.ten};
    }
`;
export const AttributionSlideButtonLeft = styled(AttributionSlideButton)`
    img {
        transform: rotate(180deg);
    }
`;

export const AttributionSlideButtonRight = styled(AttributionSlideButton)``;

export const AttributionSlidesWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 764px;

    overflow-x: hidden;
`;
export const AttributionSlidesSlidingContainer = styled.div<IStyledAttributionSlidesSlidingContainer>`
    display: flex;
    flex-wrap: nowrap;
    transition: all 200ms ease-in-out;
    transform: translate3d(-${(props) => props.slidingFactor * (764 + 20) + 'px'}, 0, 0);
`;
