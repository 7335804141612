import styled from 'styled-components';
import { Container } from '../../components/Scaffolding/Container';
import { Text } from '../../components/Typography/Text';
import Link from 'redux-first-router-link';
import {DownloadButtonComponent} from '../../components/DownloadButton/style';

export const HomeComponent = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    
    background-image: url('/homebackground.png');
    background-size: cover;
    background-position: center center;
`;

export const HomeContainer = styled(Container)`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 100%;
    padding: ${({ theme }) => theme.spacers.vertical.desktop.s } 0;

    ${DownloadButtonComponent} {
        margin-top: ${({ theme }) => theme.spacers.vertical.desktop.s };
    }
`;

export const ChildrenContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const HomeText = styled(Text)`
    align-self: center;

    color: ${({ theme }) => theme.colors.monochrome.two };
    
    a {
        color: white;
    }
`;

export const NextLink = styled(Link)`
    display: inline-block;
    margin-top: ${({ theme }) => theme.spacers.vertical.desktop.xs };;

    font-family: Roboto;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.monochrome.two };
`;

export const LightLogo = styled.img`
    display: block;
`;

export const DefaultFileButton = styled.button`
    margin-top: 50px;
    padding: 12px 24px;

    background-color: ${(props) => props.disabled ? 'grey' : '#009E54' };
    border: none;
    border-radius: 4px;

    font-family: Roboto;
    color: white;

    transition: all 200ms ease-out;

    &:hover {
        background-color: ${(props) => props.disabled ? 'grey' : '#52BC89' };
        cursor: ${(props) => props.disabled ? 'normal' : 'pointer'}
    }
`;