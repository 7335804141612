import { TApplicationState } from "../store";
import { IFundsState } from "../../ts/redux";
import { TReducerSelector } from "../../ts/general";
import { FUNDS_REDUCER_KEY } from "./reducer";
import { IFundsComponent } from "../../ts/components";

const reducerState: TReducerSelector<IFundsState> = (state: TApplicationState) => state[FUNDS_REDUCER_KEY];

export const getFundsTitle = (state: TApplicationState): string => reducerState(state).title;

export const getHeroFundsComponent = (state: TApplicationState): IFundsComponent => reducerState(state).hero;

export const getVillainFundsComponent = (state: TApplicationState): IFundsComponent => reducerState(state).villain;