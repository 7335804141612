import { TApplicationState } from "../store";
import { ATTRIBUTION_REDUCER_KEY } from "./reducer";
import { getValueType } from "../story/selectors";
import { TReducerSelector } from "../../ts/general";
import { IAttributionState, IAttributionCategory } from "../../ts/redux";
import { IAttributionComponent } from "../../ts/components";

const reducerState: TReducerSelector<IAttributionState> = (state: TApplicationState) => state[ATTRIBUTION_REDUCER_KEY];

export const getTitle = (state: TApplicationState) => reducerState(state).title[getValueType(state)];

export const getAttributionCategories = (state: TApplicationState) => reducerState(state).factors.map((attributionCategory: IAttributionCategory): IAttributionComponent => {
    return {
        name: attributionCategory.title, 
        thumbValue: attributionCategory.number[getValueType(state)],
        slideValue: attributionCategory.text[getValueType(state)],
        illustrationUrl: attributionCategory.icon
    };
});