import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { connectRoutes, Options } from 'redux-first-router';
import createSagaMiddleware from 'redux-saga';

// import rootReducer from './reducers';
import { rootSaga } from './sagas';
import { routesMap } from './routes';
import {storyReducer, STORY_REDUCER_KEY} from './story/reducer';
import {introductionReducer, INTRODUCTION_REDUCER_KEY} from './introduction/reducer';
import {factsheetReducer, FACTSHEET_REDUCER_KEY} from './factsheet/reducer';
import {attributionReducer, ATTRIBUTION_REDUCER_KEY} from './attribution/reducer';
import {newsReducer, NEWS_REDUCER_KEY} from './news/reducer';
import { LOCATION_REDUCER_KEY } from './location/reducer';
import { FILE_UPLOAD_REDUCER_KEY, fileUploadReducer } from './fileupload/reducer';
import createConsoleLoggerMiddleware from './consoleMiddleware';
import { FUNDS_REDUCER_KEY, fundsReducer } from './funds/reducer';

// Configure sagas.
const sagaMiddleware = createSagaMiddleware();

// Configure redux-first-router
const routeOptions: Options = {
    initialDispatch: true
};

const { reducer: routeReducer, middleware: routeMiddleware, enhancer: routeEnhancer } = connectRoutes(routesMap, routeOptions)
const middlewares = applyMiddleware(sagaMiddleware, routeMiddleware, createConsoleLoggerMiddleware());
const enhancers = compose(routeEnhancer, middlewares);

const rootReducer = combineReducers({
    [FILE_UPLOAD_REDUCER_KEY]: fileUploadReducer,
    [STORY_REDUCER_KEY]: storyReducer,
    [INTRODUCTION_REDUCER_KEY]: introductionReducer,
    [FACTSHEET_REDUCER_KEY]: factsheetReducer,
    [ATTRIBUTION_REDUCER_KEY]: attributionReducer,
    [NEWS_REDUCER_KEY]: newsReducer,
    [FUNDS_REDUCER_KEY]: fundsReducer,
    [LOCATION_REDUCER_KEY]: routeReducer
});

export type TApplicationState = ReturnType<typeof rootReducer>;

const store = createStore(
    rootReducer,
    enhancers
);

sagaMiddleware.run(rootSaga);

export default store;