import { takeLatest, call, all, put } from 'redux-saga/effects';
import { CREATE_STORY } from './types';
import { introductionFormatter } from '../../services/formatters/introduction.formatter';
import { factsheetFormatter } from '../../services/formatters/factsheet.formatter';
import { attributionFormatter } from '../../services/formatters/attribution.formatter';
import { newsFormatter } from '../../services/formatters/news.formatter';
import { updateIntroductionData } from '../introduction/actions';
import { updateFactsheetData } from '../factsheet/actions';
import { updateAttriutionData } from '../attribution/actions';
import { updateNewsData } from '../news/actions';
import { createStoryComplete } from './actions';
import { toStory } from '../location/actions';
import { IAPIResponse } from '../../ts/api';
import { fundsFormatter } from '../../services/formatters/funds.formatter';
import { updateFundsData } from '../funds/actions';

function* createStory(payload: IAPIResponse): any {

    const [
        introductionData,
        factsheetData,
        attributionData,
        newsData,
        fundsData
    ] = yield all([
        call(introductionFormatter, payload),
        call(factsheetFormatter, payload),
        call(attributionFormatter, payload),
        call(newsFormatter, payload),
        call(fundsFormatter, payload)
    ]);
    
    yield all([
        put(updateIntroductionData(introductionData)),
        put(updateFactsheetData(factsheetData)),
        put(updateAttriutionData(attributionData)),
        put(updateNewsData(newsData)),
        put(updateFundsData(fundsData))
    ]);
    
    yield all([
        put(createStoryComplete()),
        put(toStory())
    ]);
}

interface APIAction {
    type: string,
    payload: IAPIResponse
}
export function* storySagas() {
    yield takeLatest(CREATE_STORY, (action: APIAction) => createStory(action.payload)); // TODO Make this a cleaner implemantation of IACtion.
}