import React, { ReactElement } from "react";
import { Container } from "../../components/Scaffolding/Container";
import { TApplicationState } from "../../redux/store";
import { IFundsComponent } from "../../ts/components";
import { getFundsTitle, getHeroFundsComponent, getVillainFundsComponent } from "../../redux/funds/selectors";
import { connect } from "react-redux";
import { H2 } from "../../components/Typography/headers";
import { FundsWrapper, FundsItemVillain, FundsItemHero } from "./style";
import { FUNDS_ILLUSTRATION_PATH } from "../../config/assets.config";

interface IFundsReduxProps {
    title: string;
    hero: IFundsComponent,
    villain: IFundsComponent
}


function Funds(props: IFundsReduxProps): ReactElement {
    return (
        <Container>
            <hr/>
            <H2>{props.title}</H2>
            <FundsWrapper>
                <FundsItemHero>
                    <img src={FUNDS_ILLUSTRATION_PATH + props.hero.icon} alt="hero" />
                    <span dangerouslySetInnerHTML={{__html: props.hero.text}}></span>
                </FundsItemHero>            
                <FundsItemVillain>
                    <span dangerouslySetInnerHTML={{__html: props.villain.text}}></span>
                    <img src={FUNDS_ILLUSTRATION_PATH + props.villain.icon} alt="Villain" />
                </FundsItemVillain>
            </FundsWrapper>
        </Container>
    );
}

function mapStateToProps(state: TApplicationState): IFundsReduxProps {
    return {
        title: getFundsTitle(state),
        hero: getHeroFundsComponent(state),
        villain: getVillainFundsComponent(state)
    }
}

export default connect(mapStateToProps)(Funds);