import { DefaultTheme } from 'styled-components';

export const StoryTellerTheme: DefaultTheme = {
    colors: {
        primary: {
            one: '#E7285D',
            two: '#0000FF',
        },
        system: {
            warning: '#FF7200',
            success: '#9ACC04',
            info: '#0000FF',
            error: '#FF0020',
            link: '#0000FF',
            disabled: '#3A3F44',
            text: '#171A2F'
        },
        monochrome: {
            one: '#000000',
            two: '#FFFFFF',
            three: '#3A3F44',
            four: '#747474',
            five: '#C1C7CC',
            six: '#F5F5F5',
            seven: '#CBCBCB',
            eight: '#D8D8D8',
            nine: '#E1E1E1',
            ten: '#fcfcfc',
        }
    },
    spacers: {
        vertical: {
            desktop: {
                xs: '24px',
                s: '48px',
                m: '80px',
                l: '120px',
                xl: '184px',
                xxl: '240px'
            },
            mobile: {
                xs: '16px',
                s: '24px',
                m: '40px',
                l: '64px',
                xl: '80px',
                xxl: '120px'
            }
        },
        horizontal: {
            desktop: {
                xxs: '16px',
                xs: '24px',
                s: '48px',
                m: '80px',
                l: '120px',
            }
        }
    },
    layers: {
        belowBaseLayer: 0,
        baseLayer: 1,
        overBaseLayer: 10,
        overlayLayer: 100
    }
};