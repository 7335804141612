
import { IAPIResponse } from "../../ts/api";
import { IFactsheetState } from "../../ts/redux";


export function factsheetFormatter(payload: IAPIResponse): Promise<IFactsheetState> {
    const factsheetData = payload.factsheet;

    return Promise.resolve({
        information: factsheetData.information,
        comparison: {
            show: factsheetData.comparison.show,
            hide: factsheetData.comparison.hide,
        },
        current: factsheetData.current,
        previous: factsheetData.previous,
        quote: factsheetData.explanation.quote,
        explanation: factsheetData.explanation.general
    });
}