import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import HomeWrapper from './HomeWrapper';
import Stepper from '../../components/Stepper';
import { NextLink } from './style';
import { Text } from '../../components/Typography/Text';

import xlsxSvg from '../../assets/icons/xlsx.svg';
import DownloadButton from '../../components/DownloadButton';
import { generateDefautlFileReportStart } from '../../redux/fileupload/actions';
import { toFileUpload } from '../../redux/location/actions';

interface IHomeReduxDispatchProps {
    proceedWithDefaultFile(): void;
}

interface IHomeProps {}

type THomeProps = IHomeProps & IHomeReduxDispatchProps;

function Home(props: THomeProps): ReactElement {
    return (
        <HomeWrapper>
            <Stepper steps={[true, false, false]}/>
            <Text large light align="center">First <strong>download</strong> our template XLSX file and fill in the necessary data. Then proceed to the next step.</Text>
            <img src={xlsxSvg} alt="Excel file icon" />
            <DownloadButton />1
            <NextLink to="/upload">Next</NextLink>
            {/* <DefaultFileButton
                type="button"
                onClick={props.proceedWithDefaultFile}
            >
                Proceed with default file
            </DefaultFileButton> */}
        </HomeWrapper>
    );       
}

// Easier for debugging now.
function mapDispatchToProps(dispatch: Dispatch) {
    return {
        proceedWithDefaultFile: () => {
            dispatch(toFileUpload());
            dispatch(generateDefautlFileReportStart());
        }
    }
}

export default connect(null, mapDispatchToProps)(Home);