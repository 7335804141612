import styled from 'styled-components';

export const Container = styled.div`
    position: relative;

    display: block;
    width: 100%;
    max-width: 1024px;
    margin: auto auto;
    padding: 0 48px;

    & + & {
        margin-top: ${({theme}) => theme.spacers.vertical.desktop.m};
    }
`;