import React, { FunctionComponent } from 'react';
// import { Link } from 'react-router-dom';
import { HomeComponent, HomeContainer, HomeText, ChildrenContainer } from './style';

import lightLogo from '../../assets/images/logo_light.svg';

interface IHomeWrapperProps {}

const HomeWrapper: FunctionComponent<IHomeWrapperProps> = ({children}) => 
// export default function Home({children}): FunctionComponent<IHomeProps> {
        <HomeComponent>
            <HomeContainer>
                <img src={lightLogo} alt="InvestSuite"/>
                <ChildrenContainer>
                    {children}
                </ChildrenContainer>
                <HomeText align="center">Need help? Don't hesitate to <a href="https://www.investsuite.com/contact" target="_blank" rel="noreferrer noopener">contact us</a>!</HomeText>
            </HomeContainer>
        </HomeComponent>
;

export default HomeWrapper;