import { SWITCH_VALUE_TYPE, CREATE_STORY_COMPLETE } from "./types";
import { IAction } from "../../ts/redux";

export enum ValueType {
    ABSOLUTE = 'value',
    PERCENTAGE = 'percentageValue'
};

export interface IStoryState {
    valueType: ValueType,
    hasLoadedStory: boolean,
};

const defaultState: IStoryState = {
    valueType: ValueType.ABSOLUTE,
    hasLoadedStory: false,
}

export const STORY_REDUCER_KEY: string = 'story';
export function storyReducer(state: IStoryState = defaultState, action: IAction): IStoryState {
    switch (action.type) {
        case CREATE_STORY_COMPLETE: 
            return {
                ...state,
                hasLoadedStory: true
            };
        case SWITCH_VALUE_TYPE:
            return {
                ...state,
                valueType: (state.valueType === ValueType.ABSOLUTE) ? ValueType.PERCENTAGE : ValueType.ABSOLUTE
            };
        default: 
            return state;
    }
}