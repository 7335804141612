import { createAction } from "../../utils/redux/createAction";
import { SWITCH_VALUE_TYPE, CREATE_STORY, CREATE_STORY_COMPLETE } from "./types";
import { IAction } from "../../ts/redux";
import { IAPIResponse } from "../../ts/api";

export function switchValueType(): IAction {
    return createAction(SWITCH_VALUE_TYPE);
}

export function createStory(payload: IAPIResponse): IAction {
    return createAction(CREATE_STORY, payload);
}

export function createStoryComplete(): IAction {
    return createAction(CREATE_STORY_COMPLETE);
}