import React, { ReactElement } from 'react';
import { StepperDot, StepperComponent, StepperLine } from './style';

interface IStepperProps {
    steps: Array<boolean>,

}

export default function Stepper({steps}: IStepperProps): ReactElement {

    const stepperItems = steps.map((stepValue, index) => {
        let returnValue = [];

        if (index !== 0) {
            returnValue.push(<StepperLine key={'line-' + index} active={stepValue} />);
        }

        returnValue.push(<StepperDot key={'dot-' + index} active={stepValue} />);
        return returnValue;
    });

    return (
        <StepperComponent>
            {stepperItems}
        </StepperComponent>
    );
}