import { createAction } from "../../utils/redux/createAction";
import { ROUTE_KEYS } from "../routes";
import { redirect } from "redux-first-router";
import { Action } from "redux";
import { IAction } from "../../ts/redux";

export function toStory(): IAction {
    return createAction(ROUTE_KEYS.R_STORY);
}

export function toHome(): Action {
    return redirect(createAction(ROUTE_KEYS.R_HOME));
}

export function toFileUpload(): Action {
    return createAction(ROUTE_KEYS.R_UPLOAD);
}