import React, { ReactElement } from 'react';
import { AttributionCategoryThumbComponent, AttributionValue, AttributionName } from './style';
import { IAttributionThumbComponent } from '../../ts/components';

export default function AttributionCategoryThumb({name, value, activeIndex, index, clickHandler}: IAttributionThumbComponent): ReactElement {
    return (
        <AttributionCategoryThumbComponent onClick={clickHandler} active={activeIndex === index}>
            <AttributionName>{name}</AttributionName>
            <AttributionValue>{value}</AttributionValue>
        </AttributionCategoryThumbComponent>
    );
}