import { IFundsState, IAction } from "../../ts/redux";
import { UPDATE_FUNDS_DATA } from "./types";

const defaultState: IFundsState = {
    title: '',
    hero: {
        icon: '',
        text: '',
    },
    villain: {
        icon: '',
        text: '',
    }
}

export const FUNDS_REDUCER_KEY: string = 'funds';
export function fundsReducer(state: IFundsState = defaultState, action: IAction): IFundsState {
    switch (action.type) {
        case UPDATE_FUNDS_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}