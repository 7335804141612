import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const LoaderComponent = styled.div`
    position: relative;

    display: block;
    width: 40px;
    height: 40px;

    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme.colors.monochrome.two };

    animation: ${rotation} 1s linear infinite;
`;

export const HightLight = styled.div`
    position: absolute;
    top: -4px;
    left: -2px;

    display: block;
    width: 40px;
    height: 40px;

    border-radius: 100%;
    border: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.colors.primary.one };
`;

